<template>
  <v-card
    data-testid="hub-profile-menu-container"
    class="pa-4 rounded-lg shadow-regular"
  >
    <v-list nav dense>
      <v-list-item-group mandatory color="blueRegular">
        <v-list-item
          v-for="(button, index) in filteredMenuButtons"
          :key="index"
          :data-testid="button.dataTestid"
          :to="{ name: button.route }"
        >
          <v-list-item-content>
            <v-list-item-title class="body-text-4" @click="scrollUp()">
              {{ button.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script setup>
import languages from '../locales/languages';
import { usePermissions } from '@/helpers/composables/permissions.js';
import { useI18n } from 'vue-i18n-bridge';
import { computed } from 'vue';

const { canUser } = usePermissions();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Methods ---
const scrollUp = () => {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
};

// --- Computed ---
const menuButtons = computed(() => {
  return [
    {
      name: t('Msg_.hubProfileMenu.masterData'),
      route: 'HubProfileMasterData',
      permission: [
        'Hubs.ReadMasterContact',
        'Hubs.ReadMasterBuilding',
        'Hubs.ReadMasterStats',
      ],
      dataTestid: 'hub-profile-menu-master-data-btn',
    },
    {
      name: t('Msg_.hubProfileMenu.employees'),
      route: 'HubProfileEmployees',
      permission: ['People.Employees.ListActive', 'People.Employees.ListAll'],
      dataTestid: 'hub-profile-menu-employees-btn',
    },
    {
      name: t('Msg_.hubProfileMenu.operations'),
      route: 'HubProfileOperations',
      permission: [
        'Hubs.ReadOperationsGeneral',
        'Hubs.ReadOperationsBillingHub',
        'Hubs.ReadOperationsWorkingHours',
        'Hubs.ReadOperationsInsurance',
      ],
      dataTestid: 'hub-profile-menu-operations-btn',
    },
    {
      name: t('Msg_.hubProfileMenu.history'),
      route: 'HubProfileHistory',
      permission: ['Hubs.ViewHistory'],
      dataTestid: 'hub-profile-menu-history-btn',
    },
    {
      name: t('Msg_.hubProfileMenu.downloads'),
      route: 'HubProfileDownloads',
      permission: ['Hubs.DownloadFiles'],
      dataTestid: 'hub-profile-menu-downloads-btn',
    },
  ];
});

const filteredMenuButtons = computed(() => {
  return menuButtons.value.filter((button) => canUser(button.permission));
});
</script>
