<template>
  <v-container>
    <v-row class="pt-3">
      <v-col cols="auto" class="heading-h2 mb-2" data-testid="hub-menu-title">
        {{ t('Title_.page.hubProfile') }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <HubProfileCard />
        <HubProfileMenu class="mt-8" />
      </v-col>
      <v-col
        v-if="loadingStore.isPartialLoading"
        cols="8"
        class="d-flex justify-center align-center"
      >
        <LoadingAnimation dense />
      </v-col>
      <v-col cols="8" v-show="!loadingStore.isPartialLoading">
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import LoadingAnimation from '@/components/shared/customComponents/loadingAnimation/loading-animation.vue';
import HubProfileCard from './hub-profile-card.vue';
import HubProfileMenu from './hub-profile-menu.vue';
import languages from '../locales/languages';
import { useI18n } from 'vue-i18n-bridge';
import { useRoute } from 'vue-router/composables';
import { useHubsStore } from '@/store/pinia/hubs.store.js';
import { usePeopleStore } from '@/store/pinia/people.store.js';
import { useLoadingStore } from '@/store/pinia/loading.store.js';
import { amplitudeComposable } from '@/helpers/composables/amplitude.js';
import { watch } from 'vue';

const { trackPage } = amplitudeComposable();
const route = useRoute();
const hubsStore = useHubsStore();
const peopleStore = usePeopleStore();
const loadingStore = useLoadingStore();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Methods ---
const getHubMenuInformation = async () => {
  try {
    await hubsStore.getHubInfoById(route.params.hubId);
  } catch (error) {
    console.log(error);
  }
};

const getHubResponsibleEmployeesById = async () => {
  try {
    await hubsStore.getHubResponsibleEmployeesById(route.params.hubId);
  } catch (error) {
    console.log(error);
  }
};

const getBillingHubs = async () => {
  try {
    await hubsStore.getBillingHubs();
  } catch (error) {
    console.log(error);
  }
};

const getCompanies = async () => {
  try {
    await hubsStore.getCompanies();
  } catch (error) {
    console.log(error);
  }
};

const getPeopleHubList = async () => {
  try {
    await peopleStore.getPeopleHubList();
  } catch (error) {
    console.log(error);
  }
};

const scrollUp = () => {
  window.scrollTo(0, 0);
};

const initializeHubProfile = () => {
  scrollUp();
  getHubMenuInformation();
  getHubResponsibleEmployeesById();
  getBillingHubs();
  getCompanies();
  getPeopleHubList();
  trackPage();
};

// --- Watchers ---
watch(
  () => route.name,
  () => {
    trackPage();
  }
);

// --- Lifecycle hooks ---
initializeHubProfile();
</script>

<style scoped lang="scss"></style>
