<template>
  <v-card class="rounded-lg shadow-regular">
    <v-card-text class="py-5 px-5">
      <v-container class="mb-2">
        <v-row no-gutters>
          <v-col cols="12" class="d-flex justify-end mb-2">
            <v-chip
              v-if="hubsStore.hubInfo.status !== 0"
              data-testid="hub-profile-card-status"
              :color="statusChipColor()"
              label
              :text-color="statusChipTextColor()"
              class="mb-1"
              small
            >
              <span class="caption-3 font-weight-medium">
                {{ hubOperationStatus }}
              </span>
            </v-chip>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <h4
              data-testid="hub-profile-card-name"
              class="heading-h4 black--text mb-n1"
            >
              {{ hubsStore.hubInfo.name || '-' }}
            </h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-if="hubsStore.hubInfo.hubType"
            class="d-flex align-center pt-0"
          >
            <img
              data-testid="hub-profile-card-type-icon"
              :src="hubTypeIcon"
              class="mr-3"
            />
            <span
              data-testid="hub-profile-card-type"
              class="overline-2 hub-bubble-text"
            >
              {{ hubTypeMessage }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex align-center pt-4 pb-0">
            <img
              data-testid="hub-profile-card-number-icon"
              :src="FingerprintIcon"
              class="mr-3"
            />
            <span data-testid="hub-profile-card-number" class="body-text-4">
              {{ hubsStore.hubInfo.hubNumber || '-' }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex align-center pt-2 pb-0">
            <img
              data-testid="hub-profile-card-company-icon"
              :src="CompanyIcon"
              class="mr-3"
            />
            <span data-testid="hub-profile-card-company" class="body-text-4">
              {{ hubsStore.hubInfo.companyName || '-' }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex align-center pt-2 pb-0">
            <img
              data-testid="hub-profile-card-team-planner-icon"
              :src="StarOutlineIcon"
              class="mr-3"
            />
            <span
              data-testid="hub-profile-card-team-planner"
              class="body-text-4"
            >
              {{ hubsStore.hubInfo.mainResponsibleTeamPlannerName || '-' }}
            </span>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="hubsStore.hubInfo.hubType">
        <v-row no-gutters>
          <v-col>
            <v-divider data-testid="hub-profile-card-divider-line" />
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="hubsStore.hubInfo.hubType">
        <v-row>
          <v-col>
            <h4
              data-testid="hub-profile-card-hub-bubble-subtitle"
              class="overline-2 hub-bubble-text"
            >
              {{ t('Title_.subTitle.hubBubble') }}
            </h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0">
            <div class="d-flex align-center">
              <img
                data-testid="hub-profile-card-hub-bubble-billing-hub-icon"
                :src="
                  getHubBubbleIconColor(
                    hubsStore.hubInfo.hubBubble?.billingHubName,
                    true
                  )
                "
                class="mr-3"
              />
              <span
                data-testid="hub-profile-card-hub-bubble-billing-hub"
                class="caption-1 hub-bubble-hover-effect"
                :class="
                  getHubBubbleTextColor(
                    hubsStore.hubInfo.hubBubble?.billingHubName
                  )
                "
                @click="
                  openHubInNewTab(hubsStore.hubInfo.hubBubble?.billingHubId)
                "
              >
                {{ hubsStore.hubInfo.hubBubble?.billingHubName || '-' }}
              </span>
            </div>
            <div class="pl-4">
              <div
                v-for="(hub, index) in hubsStore.hubInfo.hubBubble?.subHubs"
                :key="hub.id || index"
                class="d-flex align-center mt-2"
              >
                <img
                  :data-testid="`hub-profile-card-hub-bubble-subhub-${index}-icon`"
                  :src="getHubBubbleIconColor(hub.hubName, false)"
                  class="mr-3"
                />
                <span
                  :data-testid="`hub-profile-card-hub-bubble-subhub-${index}`"
                  :class="getHubBubbleTextColor(hub.hubName)"
                  class="caption-1 hub-bubble-hover-effect"
                  @click="openHubInNewTab(hub.hubId)"
                >
                  {{ hub.hubName || '-' }}
                </span>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script setup>
import BillingHubIcon from '@/assets/icons/billing-hub-icon.svg';
import BillingHubIconBlue from '@/assets/icons/billing-hub-icon-blue.svg';
import SubhubIcon from '@/assets/icons/subhub-icon.svg';
import SubhubIconBlue from '@/assets/icons/subhub-icon-blue.svg';
import FingerprintIcon from '@/assets/icons/fingerprint-icon.svg';
import CompanyIcon from '@/assets/icons/company-icon.svg';
import StarOutlineIcon from '@/assets/icons/star-outline-icon.svg';
import languages from '../locales/languages';
import { useI18n } from 'vue-i18n-bridge';
import { useHubsStore } from '@/store/pinia/hubs.store.js';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';

const route = useRoute();
const router = useRouter();
const hubsStore = useHubsStore();
const { t, tm } = useI18n({ locale: 'en', messages: languages });

// --- Methods ---
const getHubBubbleIconColor = (hubName, isBillingHub) => {
  if (isBillingHub) {
    return hubsStore.hubInfo.name === hubName
      ? BillingHubIconBlue
      : BillingHubIcon;
  }
  return hubsStore.hubInfo.name === hubName ? SubhubIconBlue : SubhubIcon;
};

const getHubBubbleTextColor = (hubName) => {
  return hubsStore.hubInfo.name === hubName ? 'blueRegular--text' : '';
};

const statusChipColor = () => {
  switch (hubsStore.hubInfo.status) {
    case 11:
      return '#DAE9FF'; // blueLight in the new design
    case 21:
      return '#E6FBD9'; // greenLight in the new design
    case 201:
      return 'ashRegular';
  }
};

const statusChipTextColor = () => {
  switch (hubsStore.hubInfo.status) {
    case 11:
      return 'blueRegular';
    case 21:
      return 'caribbeanDark';
    case 201:
      return 'mineBlack';
  }
};

const openHubInNewTab = async (id) => {
  if (route.params.hubId !== id) {
    const routeData = router.resolve({
      name: 'HubProfile',
      params: { hubId: id },
    });
    window.open(routeData.href, '_blank');
  }
};

// --- Computed ---
const hubTypeIcon = computed(() => {
  return hubsStore.hubInfo.hubType === 1 ? BillingHubIcon : SubhubIcon;
});

const hubTypeMessage = computed(() => {
  return hubsStore.hubInfo.hubType === 1
    ? t('Msg_.billingHub')
    : `${t('Msg_.subhubOf')} ${
        hubsStore.hubInfo.hubBubble?.billingHubName || '-'
      }`;
});

const hubOperationStatus = computed(() => {
  return tm(`Action_.selectOperationTypes[${hubsStore.hubInfo.status}]`);
});
</script>

<style scoped lang="scss">
.hub-bubble-text {
  font-size: 12px !important;
  color: $grey-dark !important;
}

.hub-bubble-hover-effect:hover {
  color: $mine-black;
  cursor: pointer;
}
</style>
